
export function iconsComponent(data, container, $, builder) {

	const holderID = builder.getFieldID();
	let out = `
	<div id="${holderID}">
		${getIcons()}
	</div>
	`;

	this.eventOnload(() => {
		// Will add click event when template has loaded
		let holderElem = $("#"+holderID);
		holderElem.find(".wa-choose-icon-btn").click((e, target) => {
			e.preventDefault();
			const index = $(target).index();
			data.selected = (data.feed[index] ?? null);

			if(typeof data.selected === "object") {
				let btn = window.app.data.domerGetBtn.icon, value = btn.find(".value"), inp = btn.find("input");
				value.html(data.selected.icon);
				inp.val(encodeURIComponent(data.selected.icon));
				container.get("modal-close");
			} else {
				alert("Could not select the icon. Reload your browser and try again.")
			}
		});
	});
	
	function getIcons() {
		let out = "";
		out += `<div class="items flex-wrap">`;
		$.each(data.feed, function(key, row) {
			out += `
			<a class="test mb align-center block wa-choose-icon-btn color-primary text-dec-none p-20" href="#">
				<span class="mb-10 block">
					${row.icon}
				</span>
				<span class="block legend color-primary">${row.title}</span>
			</a>
			`;
		});
		out += `</div>`;
		return out;
	}

	return out;
}
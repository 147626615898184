import { StratoxDom as $ } from 'stratoxdom/src/StratoxDom.js';
import { Stratox } from 'stratox';
import { FormTemplateFields } from './FormTemplateFields.js';
import { tooltipComponent } from '../jviews/tooltip.js';
import { tableComponent } from '../jviews/table.js';
import { ingressComponent } from '../jviews/ingress.js';
import { iconsComponent } from '../jviews/icons.js';
import { formComponent } from '../jviews/form.js';
import { modalComponent } from '../jviews/modal.js';
import { app } from './app.js';
import Quill from 'quill';
//import { Validate } from './form/Validate.js';

app.init({
    lang: "sv",
    template: {
        //formHandler: FormTemplateFields,
        directory: "/",
        handlers: {
            fields: FormTemplateFields, // Optional: will add form builder (se bellow)
            helper: function() {
                // Pass on helper classes, functions and objects to your views
                return $;
            }
        }
    },
    phrases: {
        0: "An unexpected error has occurred",                 
        204: "204 The document is empty",
        400: "400 The server understood the request but the content was invalid",
        401: "401 Unauthorized",
        403: "403 Forbidden",
        404: "404 The page could not be found",
        414: "414 Request-URI Too Long",
        500: "500 Internal Server Error, try agin later",
        503: "503 Service Unavailable"
    },
    responder: {
        setup: function(data) {
            Stratox.prepareView("tooltip", tooltipComponent);
            Stratox.prepareView("table", tableComponent);
            Stratox.prepareView("ingress", ingressComponent);
            Stratox.prepareView("modal", modalComponent);
            Stratox.prepareView("icons", iconsComponent);
            Stratox.prepareView("form", formComponent);
        },
        ready: function(data) {
            const Link = Quill.import('formats/link');
            class LinkOpt extends Link {
                static create(value) {
                    let node = super.create(value);
                    value = this.sanitize(value);
                    node.setAttribute('href', value);
                    node.removeAttribute('target');
                    return node;
                }
            }

            Quill.register(LinkOpt);

            // Bind editor component to Stratox load events (onload trigger on load and done on change)
            const compFormView = app.getView('form#waViewforms');
            if(compFormView) {
                compFormView.done(this.componentEditors);
            }

            // Main edior
            $(".editor").each(function() {
                let editor = $(this), inp = editor.next(), quill = new Quill(editor.get(0), {
                    theme: 'snow'
                });    
                quill.on('text-change', function(delta, oldDelta, source) {
                    inp.val(quill.root.innerHTML);
                });
            });

            // Main delete request
            $(document).on("click", ".domer-delete-btn", function(e) {
                e.preventDefault();
                let btn = $(this), url = btn.data("href"), tr = btn.closest("tr");
                app.confirm("confirm", "Är du säker på att du vill fortsätta?", function() {
                    tr.remove();
                    app.ajax({ url: url });
                });
            });
        },
        update: function(data) {
            console.log("update", data);
        },
        componentEditors(data) {
            if(data?.json?.['form#defualt'].fields) {
                this.eventOnload(function() {
                    $(".componet-editor").each(function() {
                        let editor = $(this), inp = editor.next(), quill = new Quill(editor.get(0), {
                            theme: 'snow',
                            modules: {
                                toolbar: [
                                    [{ 'header': [4, 5, 6, false] }],
                                    [{ 'align': [] }],
                                    ['bold', 'italic'],
                                    [{ 'list': 'bullet'}, { 'list': 'ordered' }],
                                    ['bold', 'link']
                                ],
                                clipboard: {
                                    matchVisual: false
                                }
                            }
                        });

                        quill.on('text-change', function(delta, oldDelta, source) {
                            inp.val(quill.root.innerHTML);
                            inp.trigger("input");
                        });
                    });
                });
            }

        }
    }
});

$(document).ready(app.setup);

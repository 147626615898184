
export function formComponent(data, container, $, builder) {
	let out = '';
	out += `<div class="article bg-white border">`;
	out += `<form action="${data.action}" method="${data.method}">`;
	builder.groupFactory(function(o, val) {
        out += o;
    });
	out += `<input class="inp-csrf-token" type="hidden" name="csrfToken" value="${data.token}">`;
	out += `<input class="button" type="submit" name="submit" value="${data.submit}">`;
    out += `</form>`;
    out += `</div>`;
	return out;
}
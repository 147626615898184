/**
 * Stratox template
 * Author: Daniel Ronkainen
 * Description: A modern JavaScript template library that redefines how developers can effortlessly create dynamic views.
 * Copyright: Apache License 2.0
 */

import { StratoxBuilder } from 'stratox/src/StratoxBuilder.js';
import { StratoxTemplate } from 'stratox/src/StratoxTemplate.js';
import { StratoxDom as $ } from 'stratoxdom/src/StratoxDom.js';

export class FormTemplateFields extends StratoxTemplate {
    /**
     * Regular input field
     * @return {string}
     */
    box() {
        const inst = this, 
        ID = this.nameJoin.replace(/,/g, "-"),
        colorInt = (inst.value?.color ?? 0);

    
        inst.containerInst.get("view").eventOnload(() => {
            const component = $("#"+ID);
            if(component.length > 0) {
                const componentBtn = component.find(".wa-change-color-btn");
                componentBtn.click(function(e) {
                    e.preventDefault();
                    let obj = $(this), 
                    inp = obj.parent().find("input"), 
                    val = obj.data("value");
                    inp.val(val);
                    inp.trigger("input");
                    inst.containerInst.get("view").observer().notify();
                });
            }
        }, 10);


        return `


        <div id="${ID}" class="component relative">
            <div class="component-field" style="background-color: ${getBg()};color: ${getColor()};">
                <div class="componet-editor" data-name="${getName('content')}">${getValue('content')}</div>
                <input id="editor-${getID('content')}" type="hidden" name="${getName('content')}" data-name="${getID('content')}" value="${encodeURIComponent(getValue('content'))}">
            </div>
            <div class="settings abs right top z-20">
                <span class="dots"></span>
                <nav class="tooltip bottom right bg-white">
                    <aside>
                        <ul>
                            <li class="p-10">
                                <div class="text-xs bold title">Bakgrundsfärg</div>
                                ${colors()}
                            </li>
                            <li class="p-10">
                                <div class="text-xs bold title mb-10">Synlighet</div>
                                ${status()}
                            </li>
                        </ul>
                    <aside>
                </nav>
            </div>
        </div>
        `;

        function colors() {
            let i, out = "", value = (inst.value?.color ?? 0);
            if(typeof inst?.config?.colors === "object" && inst.config.colors.length > 0) {
                out += `<div class="select-colors">`;

                for(i = 0; i < inst.config.colors.length; i++) {
                    out += `
                    <a class="flex wa-change-color-btn" href="#" data-value="${i}">
                        <div class="circ" style="background-color: ${inst.config.colors[i].bg};"></div>
                        <div class="title">${inst.config.colors[i].title}</div>
                    </a>
                    `;
                }
                out += `<input type="hidden" data-name="${getID('color')}" name="${getName('color')}" value="${getValue('color')}">`;
                out += `</div>`;
            }
            return out;
        }

        function getBg() {
            if(typeof inst?.config?.colors === "object" && inst.config.colors.length > 0) {
                return (inst.config.colors?.[colorInt]?.bg ?? inst.config.colors[0].bg);
            }
            return "#FFFFFF";
        }

        function getColor() {
            if(typeof inst?.config?.colors === "object" && inst.config.colors.length > 0) {
                return (inst.config.colors?.[colorInt]?.color ?? inst.config.colors[0].color);
            }
            return "#000000";
        }

        function password() {
            return "dwqwq";
        }

        function status() {
            let i, 
            out = "", 
            status = (inst.value?.status ?? 1),
            active = (parseInt(status) === 1) ? "checked " : "";
            out += `
            <label class="flex">
                <input ${active}type="checkbox" data-name="${getID('status')}" name="${getName('status')}" value="1">
                <span>Synlig</span>
            </label>
            `;
            return out;
        }

        function getName(name) {
            return inst.name+"["+name+"]";
        }

        function getID(name) {
            return inst.nameJoin+","+name;
        }

        function getValue(name, defaultValue) {
            return (inst.value[name] ?? (defaultValue ?? ""));
        }
    }

    /**
     * Group field(s)
     * @return {string}
     */
    group() {

        let addClass = "mb-20 wa-advanced-grouped-field";
        if(typeof this.attr?.class === "string") {
            addClass += " "+this.attr.class;
        }

      
        let out = '';
        out += '<div class="'+addClass+'">';
        this.groupFactory(function(o, val) {
            out += o;
        }, true);
        out += '</div>';
        return out;
    }

}